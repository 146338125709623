import React, {Component, Fragment,} from 'react';
import { ALERT_MESSAGES, APPLICATION_MODES, DIMENSION_INDUSTRY_VALUES, FORM_OPERATIONS, PROPERTY_DIMENSION_VALUE, PROPERTY_RESPONSE_TEMPLATES, STAGES_GRID, SWITCH_TEXT, TEXT_VALIDATION, TOUCHPOINTS_GRID, VALIDATION_MESSAGES } from '../../../constants/appConstants';
import Loader from 'react-loaders';
import { TatvamButton, TatvamCol, TatvamMultiSelect, TatvamTextArea, TatvamTextBox } from '../../functionalComponents';
import { Row, Switch } from 'react-materialize';
import AlertBox from '../alertBox';
import { dynamicSort } from '../../../utils/objectUtil';
import { validatePattern, validateSequenceNumber } from '../../../utils/stringUtil';

class DimensionIndustryForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldCloseModal: false,
            applicationMode: props.applicationMode,
            preLoader: false,
            status: false,
            isValid: true,
            editFlag: false,
            touchpointsListsOptions: [],
            selectedTouchpoints: [],
            parentEntityTypeListsOptions: [],
            selectedParentEntityType: [],
            entityTypeListsOptions: [],
            selectedEntityType: [],
            stagesListsOptions: [],
            selectedStages: [],
            sequence_no: "",
            validSequenceNumber: true,
            validPattern: true,
            validEntityTypeValue: true,

        }
    }

     initDimensionValues = (thisProps) => {
        //currently dimesnion are based on customer, 
        //todo: check with dhanashri
            // const parentDimensionLst = [];
            // parentDimensionLst.push({label: "", value: ""});
            // for (let data of Object.values(thisProps.customerDimensions)) {
            //     if (trimCapitalize(data.status) === APPLICATION_MODES.ACTIVE ) {
            //         // parentDimensionLst.push({label: data.entity_type, value: data.entity_type});
            //     }
            // }
            // parentDimensionLst.sort(dynamicSort("label"));
            // parentDimensionLst[0].label = PROPERTY_DIMENSION_VALUE.SELECT_PARENT_ENTITY;
    
            this.setState({
                parentEntityTypeListsOptions: [
                    {label: 'TOUCHPOINT', value: 'TOUCHPOINT'},
                    {label: 'CLASSIFIER', value: 'CLASSIFIER'},
                    {label: 'DEPARTMENT', value: 'DEPARTMENT'},
                    {label: 'LABEL', value: 'LABEL'},
                    {label: 'STAFF', value: 'STAFF'},
                ],
                entityTypeListsOptions: [
                    {label: 'TOUCHPOINT', value: 'TOUCHPOINT'},
                    {label: 'CLASSIFIER', value: 'CLASSIFIER'},
                    {label: 'DEPARTMENT', value: 'DEPARTMENT'},
                    {label: 'LABEL', value: 'LABEL'},
                    {label: 'STAFF', value: 'STAFF'},
                ]
            });
    };
    

    _handleSave = e => {
        e.preventDefault();

        this._validations().then((response) => {
            if (!this.state.isValid) {
                this.setState({inValidForm: true});
            } 
            if (response) {
                // if (this.state.isValid && this.state.validEntityTypeValue && this.state.validPattern && this.state.validSequenceNumber) {

                let dimensionIndustryData = {};
                dimensionIndustryData.touchpoints= this.state.selectedTouchpoints.label ? this.state.selectedTouchpoints.label : "";
                dimensionIndustryData.parent_entity_type = this.state.selectedParentEntityType.label ? this.state.selectedParentEntityType.label : ""
                dimensionIndustryData.entity_type = this.state.selectedEntityType.label ? this.state.selectedEntityType.label : "";
                dimensionIndustryData.sequence_no =$.trim(this.state.sequence_no) !== "" ? parseInt(this.state.sequence_no) : 0;
                dimensionIndustryData.status = this.state.status ? SWITCH_TEXT.ACTIVE : SWITCH_TEXT.INACTIVE;
                dimensionIndustryData.entity_value = this.state.entity_value ? this.state.entity_value : "";
                dimensionIndustryData.parent_entity_value = this.state.parent_entity_value ? this.state.parent_entity_value : "";
                dimensionIndustryData.fld_pattern = this.state.fld_pattern ? this.state.fld_pattern : "";
                dimensionIndustryData.text_pattern = this.state.text_pattern ? this.state.text_pattern : "";
                dimensionIndustryData.stages= this.state.selectedStages.label ? this.state.selectedStages.label : ""                
                                            
                if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
                    dimensionIndustryData = {
                    industry: this.props.industryName,
                    ...dimensionIndustryData,
                    created_by: this.props.userName
                };
                            
                this.setState({preLoader: true});
                                                
                this.props.actions.createDimensionIndustry(dimensionIndustryData).then(response => {
                    this.setState({preLoader: false});
                    if (response && response.status === 200) {
                    this.setState({shouldCloseModal: true});
                    if (response.data && response.data.message) {
                        this.setState({
                            isAlertBoxOpen: true,
                            isSuccess: true,
                            alert_information: response.data.message
                            });
                        } else {
                                this.setState({
                                isAlertBoxOpen: true,
                                isSuccess: true,
                                alert_information: response.message
                            });
                        }
                        } else {
                            this.setState({
                            shouldCloseModal: false,
                            isAlertBoxOpen: true,
                            isSuccess: false,
                            alert_information: response.data.message,
                            });
                        }
                    });
                }else {
                        let  formData = {
                              ...dimensionIndustryData,
                              industry: this.props.industryName,
                              industryDimensionId: this.props.industryDimensionValueData.id,
                              updated_by: this.props.userName
                            };
                            this.props.actions.editDimensionIndustry(formData).then((response) => {
                            this.setState({preLoader: false});
                            if (response && response.status === 200) {
                            this.setState({shouldCloseModal: true});
                            if (response.data && response.data.message) {
                            this.setState({
                                isAlertBoxOpen: true,
                                isSuccess: true,
                                alert_information: response.data.message
                            });
                            } else {
                                this.setState({
                                    isAlertBoxOpen: true,
                                    isSuccess: true,
                                    alert_information: response.message
                                    });
                                }
                                } else {
                                    this.setState({
                                        shouldCloseModal: false,
                                        isAlertBoxOpen: true,
                                        isSuccess: false,
                                        alert_information: response.data.message,
                                        applicationMode: APPLICATION_MODES.EDIT
                                        });
                                    }
                    });
                }
            //  }
            }                       
        });
    }

    loadTouchpointDropdown = (touchpointsListData) => {
        const touchpointLst = [];
            touchpointLst.push({label: "", value: ""});
            touchpointsListData.map((data) => {
                    if (data.status === APPLICATION_MODES.ACTIVE) {
                        const touchpointName = data.touchpoints;
                        touchpointLst.push({label: touchpointName, value: data.id});
                    }
            });
            touchpointLst.sort(dynamicSort("label"));
            touchpointLst[0].label = PROPERTY_RESPONSE_TEMPLATES.SELECT_SOURCE;
            this.setState({
                touchpointsListsOptions: touchpointLst
        });
    };

    loadStagesDropdown = (stagesListData) => {
            const stageLst = [];
            stageLst.push({label: "", value: ""});
            stagesListData.map((data) => {
            if (data.status === APPLICATION_MODES.ACTIVE) {
                const stageName = data.stages;
                stageLst.push({label: stageName, value: data.id});
                }
            });
            stageLst.sort(dynamicSort("label"));
            stageLst[0].label = PROPERTY_RESPONSE_TEMPLATES.SELECT_SOURCE;
            this.setState({
            stagesListsOptions: stageLst
        });
    };

    componentDidMount() {
       
        this.initDimensionValues(this.props);

        if (this.props.industryStagesList.industryStages.stages) {
            this.loadStagesDropdown(this.props.industryStagesList.industryStages.stages);
        }

        if (this.props.industryTouchpointsList.industryTouchpoints.touchpoints) {
            this.loadTouchpointDropdown(this.props.industryTouchpointsList.industryTouchpoints.touchpoints);
        }

        if (this.state.applicationMode !== APPLICATION_MODES.CREATE) {
            if (this.props.industryDimensionValueData) {
        
                let selectedTouchpoints = {label: DIMENSION_INDUSTRY_VALUES.SELECT_TOUCHPOINT, value: ""};
                let selectedParentEntityType = {label: DIMENSION_INDUSTRY_VALUES.SELECT_PARENT_ENTITY_TYPE, value: ""};
                let selectedEntityType = {label: DIMENSION_INDUSTRY_VALUES.SELECT_ENTITY_TYPE, value: ""};

                let selectedStages = {label: TOUCHPOINTS_GRID.SELECT_STAGES, value: ""};
                               
                selectedStages = {
                    label: this.props.industryDimensionValueData.stages,
                    value: this.props.industryDimensionValueData.stages
                };

                selectedTouchpoints = {
                    label: this.props.industryDimensionValueData.touchpoints,
                    value: this.props.industryDimensionValueData.touchpoints
                };
        
                selectedParentEntityType = {
                    label: this.props.industryDimensionValueData.parent_entity_type,
                    value: this.props.industryDimensionValueData.parent_entity_type
                };

                selectedEntityType = {
                    label: this.props.industryDimensionValueData.entity_type,
                    value: this.props.industryDimensionValueData.entity_type
                };
                
                this.setState({
                    ...this.props.industryDimensionValueData,
                    touchpoints: this.props.industryDimensionValueData.touchpoints,
                    selectedTouchpoints: selectedTouchpoints,
                    selectedParentEntityType:selectedParentEntityType,
                    selectedEntityType:selectedEntityType,
                    selectedStages: selectedStages,
                    status: this.props.industryDimensionValueData.status === APPLICATION_MODES.ACTIVE ? true : false
                });
        
            }
        }
    }

    componentWillReceiveProps(props) {
        this.initDimensionValues(props);

        if (props.industryStagesList.industryStages.stages) {
            this.loadStagesDropdown(props.industryStagesList.industryStages.stages);
        }


        if (props.industryTouchpointsList.industryTouchpoints.touchpoints) {
            this.loadTouchpointDropdown(props.industryTouchpointsList.industryTouchpoints.touchpoints);
        }

        if (this.state.applicationMode !== APPLICATION_MODES.CREATE) {
            if (props.industryDimensionValueData) {
        
                let selectedTouchpoints = {label: DIMENSION_INDUSTRY_VALUES.SELECT_TOUCHPOINT, value: ""};
                let selectedParentEntityType = {label: DIMENSION_INDUSTRY_VALUES.SELECT_PARENT_ENTITY_TYPE, value: ""};
                let selectedEntityType = {label: DIMENSION_INDUSTRY_VALUES.SELECT_ENTITY_TYPE, value: ""};
                let selectedStages = {label: TOUCHPOINTS_GRID.SELECT_STAGES, value: ""};
                               
                selectedStages = {
                    label: props.industryDimensionValueData.stages,
                    value: props.industryDimensionValueData.stages
                };

                selectedTouchpoints = {
                    label: props.industryDimensionValueData.touchpoints,
                    value: props.industryDimensionValueData.touchpoints
                };
        
                selectedParentEntityType = {
                    label: props.industryDimensionValueData.parent_entity_type,
                    value: props.industryDimensionValueData.parent_entity_type
                };

                selectedEntityType = {
                    label: props.industryDimensionValueData.entity_type,
                    value: props.industryDimensionValueData.entity_type
                };
                
                this.setState({
                    ...props.industryDimensionValueData,
                    touchpoints: props.industryDimensionValueData.touchpoints,
                    selectedTouchpoints: selectedTouchpoints,
                    selectedParentEntityType:selectedParentEntityType,
                    selectedEntityType:selectedEntityType,
                    selectedStages: selectedStages,
                    status: props.industryDimensionValueData.status === APPLICATION_MODES.ACTIVE ? true : false
                });
        
            }
        }
    }

    _validations = () => {
        let mandatoryFieldsValid;
        let sequenceNoFieldValid = true;
        let patternFieldValid = true;
        let isValidEntityTypeValue = true;

        if (this.state.sequence_no) {
            sequenceNoFieldValid = validateSequenceNumber(this.state.sequence_no.toString());
        }else if (!this.state.text_pattern) {
            patternFieldValid = validatePattern(this.state.text_pattern);
        }else if (!this.state.entity_value) {
            mandatoryFieldsValid = false;
        }else {
            mandatoryFieldsValid = true;
        }
        this.setState({
            isValid: mandatoryFieldsValid,
            validSequenceNumber: sequenceNoFieldValid,
            validPattern: patternFieldValid,
            validEntityTypeValue: isValidEntityTypeValue,
        });
        return Promise.resolve(true);
    }

    _handleTouchpointSelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
            },
            selectedTouchpoints: selectedOption
        });
    };

    _handleParentEntityTypeSelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
            },
            selectedParentEntityType: selectedOption
        });
    };

    _handleEntityTypeSelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
            },
            selectedEntityType: selectedOption
        });
    };

    _handleStagesSelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
            },
            selectedStages: selectedOption
        });
    };

    _handleInput = e => {
        if (e.target.id === "status") {

            this.setState({
                [e.target.id]: e.target.checked,
                editFlag: {
                    ...this.state.editFlag,
                    status: true
                }
            });
        }else {
            if (e.target.id === "sequence_no") {
                if (e.target.value !== "" && !Number(e.target.value)) {
                    return;
                }
            }

            this.setState({
                [e.target.name]: e.target.value,
                editFlag: {
                    ...this.state.editFlag,
                    [e.target.name]: true
                }
            });
        }
    };

    _triggerDiscard = () => {
            if (this.state.editFlag && Object.values(this.state.editFlag).includes(true)) {
                this.setState({
                    isConfirmBox: true,
                    isAlertBoxOpen: true,
                    isSuccess: false,
                    alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST,
                    shouldCloseModal: true
                });
            } else {
                this.props.closeModal();
            }
    };
    
    _discard = data => {
            if (data === "ok") {
                if (this.state.shouldCloseModal === true) {
                    this.setState({
                        //reset the state
                    });
                    this.props.closeModal();
                }
            }
            this.setState({
                isConfirmBox: false,
                isAlertBoxOpen: false,
                isSuccess: false
            });
    };
    
    _handleAppMode = () => {
            this.setState({
                applicationMode: APPLICATION_MODES.EDIT
            });
    };
    
    render() {
        return (
            <Fragment>
                <Row className={this.state.preLoader ? "card-container" : "card-container" + " loader-wait"}>
                    <TatvamCol s={12} className={this.state.preLoader ? "valign-wrapper form-loader-overlay" : "hide"}>
                        {this.state.preLoader && <Loader type="ball-beat"/>}
                    </TatvamCol>

                    <Row> {this.state.applicationMode === APPLICATION_MODES.VIEW ? (
                    <h4>{DIMENSION_INDUSTRY_VALUES.VIEW_TITLE_INDUSTRY_DIMENSION}</h4>) : ""}
                    {this.state.applicationMode === APPLICATION_MODES.EDIT ? (
                    <h4>{DIMENSION_INDUSTRY_VALUES.ADD_TITLE_INDUSTRY_DIMENSION}</h4>) : ""}</Row>


                    {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                    <Row className="mb-2">
                        
                        <TatvamCol m={12} className="align-flex-end">

                            <TatvamCol m={12} xl={6}>
                                    <label htmlFor="touchpoints">{TOUCHPOINTS_GRID.TOUCHPOINT_NAME}
                                    <span
                                        style={{color: 'red'}}> *</span>
                                    </label>
                                    <TatvamMultiSelect
                                        classNamePrefix="react-select"
                                        className={(this.state.inValidForm && this.state.selectedTouchpoints.value === "") ? "validate select-ht invalid-field" : "validate select-ht"}
                                        value={this.state.selectedTouchpoints}
                                        name="touchpoints"
                                        id="touchpoints"
                                        onChange={this._handleTouchpointSelect}
                                        options={this.state.touchpointsListsOptions}
                                        placeholder={DIMENSION_INDUSTRY_VALUES.SELECT_TOUCHPOINT}
                                    />
                            </TatvamCol>

                            <TatvamCol m={12} xl={6}>
                                <label htmlFor="stages">{TOUCHPOINTS_GRID.STAGES}
                                <span
                                        style={{color: 'red'}}> *</span>
                                </label>
                                    <TatvamMultiSelect
                                        classNamePrefix="react-select"
                                        className={(this.state.inValidForm && this.state.selectedStages.value === "") ? "validate select-ht invalid-field" : "validate select-ht"}
                                        value={this.state.selectedStages}
                                        name="stages"
                                        id="stages"
                                        onChange={this._handleStagesSelect}
                                        options={this.state.stagesListsOptions}
                                        placeholder={TOUCHPOINTS_GRID.SELECT_STAGES}
                                    />
                            </TatvamCol>

                        </TatvamCol>
                       
                        <TatvamCol m={12} className="align-flex-end mt-1">
                            <TatvamCol m={12} xl={6}>
                                        <label htmlFor="parent_entity_type">{'Parent Entity Type'}
                                        <span
                                        style={{color: 'red'}}> *</span>
                                        </label>
                                        <TatvamMultiSelect
                                            classNamePrefix="react-select"
                                            className={(this.state.inValidForm && this.state.selectedParentEntityType.value === "") ? "validate select-ht invalid-field" : "validate select-ht"}
                                            value={this.state.selectedParentEntityType}
                                            name="parent_entity_type"
                                            id="parent_entity_type"
                                            onChange={this._handleParentEntityTypeSelect}
                                            options={this.state.parentEntityTypeListsOptions}
                                            placeholder={'Select Parent Entity Type'}
                                        />
                                </TatvamCol>


                                <TatvamCol m={12} xl={6} className="no-pad-left">
                                    {<label htmlFor="parent_entity_value">{'Parent Entity Value'} <span
                                        style={{color: 'red'}}> *</span></label>}
                                    <TatvamTextBox
                                        className={(this.state.inValidForm && !this.state.parent_entity_value) ? "firstItem labelText mb-0 validate txt-no-h invalid" : "firstItem labelText mb-0 validate txt-no-h "}
                                        s={12}
                                        required
                                        name="parent_entity_value"
                                        onChange={this._handleInput}
                                        value={this.state.parent_entity_value}
                                        autocomplete="off"
                                        maxlength={25}
                                    />
                                </TatvamCol>
                        </TatvamCol>

                        <TatvamCol m={12} className="align-flex-end mt-1">
                                <TatvamCol m={12} xl={6}>
                                    <label htmlFor="entity_type">{'Entity Type'}
                                    <span
                                        style={{color: 'red'}}> *</span>
                                    </label>
                                    <TatvamMultiSelect
                                        classNamePrefix="react-select"
                                        className={(this.state.inValidForm && this.state.selectedEntityType.value === "") ? "validate select-ht invalid-field" : "validate select-ht"}
                                        value={this.state.selectedEntityType}
                                        name="entity_type"
                                        id="entity_type"
                                        onChange={this._handleEntityTypeSelect}
                                        options={this.state.entityTypeListsOptions}
                                        placeholder={'Select Entity Type'}
                                    />
                            </TatvamCol>


                            <TatvamCol m={12} xl={6} className="no-pad-left">
                                {<label htmlFor="entity_value">{'Entity Value'} <span
                                        style={{color: 'red'}}> *</span></label>}
                                <TatvamTextBox
                                    className={(this.state.inValidForm && !this.state.entity_value) ? "firstItem labelText mb-0 validate txt-no-h invalid" : "firstItem labelText mb-0 validate txt-no-h "}
                                    s={12}
                                    required
                                    name="entity_value"
                                    onChange={this._handleInput}
                                    value={this.state.entity_value}
                                    autocomplete="off"
                                    maxlength={25}
                                />
                            </TatvamCol>
                        </TatvamCol>

                        <TatvamCol m={12} className="mt-1">
                            
                            <TatvamCol s={12} m={4} xl={3} className="seqInput">
                                <label htmlFor="sequence_no">{STAGES_GRID.SEQ_NO}
                                <span
                                        style={{color: 'red'}}> *</span>
                                </label>
                                    <TatvamTextBox
                                        m={12}
                                        id="sequence_no"
                                        name="sequence_no"
                                        className={(this.state.inValidForm && !this.state.sequence_no) ? "labelText mb-0 validate txt-no-h invalid" : "labelText mb-0 validate txt-no-h "}
                                        onChange={this._handleInput}
                                        value={this.state.sequence_no.toString()}
                                        autocomplete="off"
                                        maxlength={3}
                                        title={TEXT_VALIDATION.SEQ_NO}
                                    />
                            </TatvamCol>

                            <TatvamCol s={12} m={4} xl={3} className="switch-wrapper">
                                <Switch
                                    id="status"
                                    offLabel="Status"
                                    checked={this.state.status}
                                    onClick={this._handleInput}
                                    m={12}
                                />
                            </TatvamCol>
                        </TatvamCol>

                        <TatvamCol m={12} className="mt-1">
                           <TatvamCol m={12} xl={12} className="pr-0">
                                <label htmlFor="fld_pattern">{'Field Pattern'}</label>
                                <TatvamTextArea
                                    s={12}
                                    m={12}
                                    l={12}
                                    xl={12}
                                    name="fld_pattern"
                                    id="fld_pattern"
                                    value={this.state.fld_pattern}
                                    onChange={this._handleInput}
                                />
                            </TatvamCol>

                            <TatvamCol m={12} xl={12} className="pr-0">
                                <label htmlFor="text_pattern">{'Text Pattern'}<span
                                    style={{color: 'red'}}> *</span></label>
                                <TatvamTextArea
                                    s={12}
                                    m={12}
                                    l={12}
                                    xl={12}
                                    name="text_pattern"
                                    id="text_pattern"
                                    className={(this.state.inValidForm && !this.state.text_pattern) ? "labelText mb-0 validate txt-no-h invalid" : "labelText mb-0 validate txt-no-h "}
                                    value={this.state.text_pattern}
                                    onChange={this._handleInput}
                                />
                            </TatvamCol>
                        </TatvamCol>

                        
                    </Row>
                ) : (
                    <Row>
                        <TatvamCol m={12} className="mt-.75 dimensionView ">
                            <div className="col s6 p-0">
                                <span
                                    className="property-label col s4"> {TOUCHPOINTS_GRID.STAGE_NAME} : </span>
                                <span className="col s8 url-wrap"> {this.state.selectedStages.label}</span>
                            </div>


                            <div className="col s6 p-0">
                                <span
                                    className="property-label col s4"> {TOUCHPOINTS_GRID.TOUCHPOINT_NAME} : </span>
                                <span className="col s8 url-wrap"> {this.state.selectedTouchpoints.label}</span>
                            </div>

                            
                        </TatvamCol>

                        <TatvamCol m={12} className="mt-.75 dimensionView ">
                        <div className="col s6 p-0">
                                <span
                                    className="property-label col s4"> {DIMENSION_INDUSTRY_VALUES.PARENT_ENTITY_TYPE} : </span>
                                <span className="col s8 url-wrap"> {this.state.selectedParentEntityType.label}</span>
                            </div> 

                            <div className="col s6 p-0">
                                <span
                                    className="property-label col s4"> {DIMENSION_INDUSTRY_VALUES.PAREN_ENTITY_VALUE} : </span>
                                <span className="col s8 url-wrap"> {this.state.parent_entity_value}</span>
                            </div>
                        </TatvamCol>


                        <TatvamCol m={12} className="mt-.75 dimensionView ">
                            
                            <div className="col s6 p-0">
                                <span
                                    className="property-label col s4"> {DIMENSION_INDUSTRY_VALUES.ENTITY_TYPE} : </span>
                                <span className="col s8 url-wrap"> {this.state.selectedEntityType.label}</span>
                            </div>

                            <div className="col s6 p-0">
                                <span
                                    className="property-label col s4"> {DIMENSION_INDUSTRY_VALUES.ENTITY_VALUE} : </span>
                                <span className="col s8 url-wrap"> {this.state.entity_value}</span>
                            </div>
                        </TatvamCol>
                     

                        <TatvamCol m={12} className="mt-.75 dimensionView ">
                            
                            <div className="col s6 p-0">
                                <span
                                    className="property-label col s4"> {DIMENSION_INDUSTRY_VALUES.SEQ_NO} : </span>
                                <span className="col s8 url-wrap"> {this.state.sequence_no.toString()}</span>
                            </div>

                            <div className="col s6 p-0">
                                <span
                                    className="property-label col s4"> {DIMENSION_INDUSTRY_VALUES.FIELD_PATTERN} : </span>
                                <span className="col s8 url-wrap"> {this.state.fld_pattern}</span>
                            </div>
                        </TatvamCol>

                        <TatvamCol m={12} className="mt-.75 dimensionView ">
                            <div className="col s6 p-0">
                                <span
                                    className="property-label col s4"> {DIMENSION_INDUSTRY_VALUES.TEXT_PATTERN} : </span>
                                <span className="col s8 url-wrap"> {this.state.text_pattern}</span>
                            </div>

                            <div className="col s6 p-0">
                                <span className="property-label col s4"> {TOUCHPOINTS_GRID.STATUS} :</span>
                                <span
                                    className="col s8">{this.state.status === true ? SWITCH_TEXT.ACTIVE : SWITCH_TEXT.INACTIVE}</span>
                            </div>
                        </TatvamCol>

                       
                    </Row>
                )}
                </Row>
                <Row className="modalButtons p-0 mt-2">
                    
                    <TatvamCol s={12} m={8}>
                        {!this.state.isValid && (
                            <p className="errorText"></p>
                        )}

                       {!this.state.validEntityTypeValue && (
                            <p className="errorText">{VALIDATION_MESSAGES.ENTITY_NAME_VALUE_INVALID}</p>
                        )}

                        {!this.state.validPattern && (
                            <p className="errorText">{VALIDATION_MESSAGES.PATTERN_INVALID}</p>
                        )}

                        {!this.state.validSequenceNumber && (
                            <p className="errorText">{VALIDATION_MESSAGES.SEQUENCE_NO_INVALID}</p>
                        )}
                    </TatvamCol>

                    <TatvamCol s={12} m={12} className="pr-2">
                        {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                                <TatvamButton
                                    waves="light"
                                    className="mt-1  CreateProjectPublish btn_primary "
                                    onClick={this._handleSave}
                                    disabled={!(this.state.editFlag && Object.values(this.state.editFlag).includes(true))}
                                >
                                    {FORM_OPERATIONS.SAVE}
                                </TatvamButton>
                            ) :
                                <TatvamButton
                                    waves="light"
                                    className="mt-1 CreateProjectPublish btn_primary "
                                    name="EDIT"
                                    onClick={this._handleAppMode}
                                    disabled={this.props.nonEditable}
                                >
                                    {FORM_OPERATIONS.EDIT}
                                </TatvamButton>
                            }
                        <TatvamButton
                            waves="light"
                            className="mt-1 mr-4 CreateProjectPublish btn_primary"
                            onClick={this._triggerDiscard}
                        >
                            {this.state.applicationMode === APPLICATION_MODES.VIEW ||
                            !(this.state.editFlag && Object.values(this.state.editFlag).includes(true)) ?
                                FORM_OPERATIONS.CLOSE : FORM_OPERATIONS.DISCARD}

                        </TatvamButton>
                    </TatvamCol>
                </Row>

                <AlertBox
                    open={this.state.isAlertBoxOpen}
                    isSuccess={this.state.isSuccess}
                    closeAlertBox={this._discard}
                    isConfirmBox={this.state.isConfirmBox}
                    alert_information={this.state.alert_information}
                />

            </Fragment>
        )
    }
}

export default DimensionIndustryForm;
