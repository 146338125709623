import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import IndustryStageForm from "./industryStageForm";
import { createIndustryStage, getIndustryStages, editIndustryStage } from "../../../actions";

const mapStateToProps = (state, ownProps) => (
    {
        userName: state.auth.userName,
        industryStagesList: state.industryStages,
    });

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            createIndustryStage,
            getIndustryStages,
            editIndustryStage
        }, dispatch
    )
});
export default connect(mapStateToProps, mapDispatchToProps)(IndustryStageForm);
