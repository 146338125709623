import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
    createDimensionIndustry,
    deleteIndustryDimension,
    editDimensionIndustry,
    fetchAllIndustry,
    getIndustryDimensionValues,
    getIndustryTouchpoints,
} from '../../../actions';
import IndustryDimensionValueForm from './industryDimensionValueForm';

const mapStateToProps = (state, ownProps) => (
    {
        userName: state.auth.userName,
        customers: state.customer.list,
        industryDimensionPermission: state.auth.permissions && state.auth.permissions.industryDimensionValue.create,
        sourceIndustryList: state.sources ? state.sources.industry : [],
        industryTouchpointsList: state.industryTouchpoints,

    });

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getIndustryDimensionValues,
            deleteIndustryDimension,
            fetchAllIndustry,
            getIndustryTouchpoints,
            createDimensionIndustry,
            editDimensionIndustry
        }, dispatch
    )
});
export default connect(mapStateToProps, mapDispatchToProps)(IndustryDimensionValueForm);
