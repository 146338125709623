import React, { Fragment } from "react";
import { ALERT_MESSAGES, APP_BOOLEANS, APPLICATION_MODES, FORM_OPERATIONS, STAGES_GRID, SWITCH_TEXT, TEXT_VALIDATION, VALIDATION_MESSAGES } from "../../../constants/appConstants";
import { TatvamButton, TatvamCol, TatvamTextBox } from "../../functionalComponents";
import AlertBox from "../alertBox";
import { Row, Switch } from "react-materialize";
import Loader from "react-loaders";
import { validateSequenceNumber } from "../../../utils/stringUtil";

class IndustryStageForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldCloseModal: false,
            applicationMode: props.applicationMode,
            preLoader: false,
            status: false,
            isValid: true,
            editFlag: false,
            sequence_no: "",
            validSequenceNumber: true,

        }
    }

    _handleSave = e => {
        e.preventDefault();
         this._validations().then((response) => {
                    if (!this.state.isValid) {
                        this.setState({inValidForm: true});
                    } 
                    if (response) {
                            let industryStageData = {};
                            industryStageData.sequence_no =$.trim(this.state.sequence_no) !== "" ? parseInt(this.state.sequence_no) : 0,
                            industryStageData.stages = this.state.stages;
                            industryStageData.status = this.state.status ? SWITCH_TEXT.ACTIVE : SWITCH_TEXT.INACTIVE;
                        
                            
                            if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
                                industryStageData = {
                                    industry_name: this.props.industryName,
                                    ...industryStageData,
                                    created_by: this.props.userName
                                };
            
                                this.setState({preLoader: true});
                                
                                this.props.actions.createIndustryStage(industryStageData).then(response => {
                                    this.setState({preLoader: false});
                                    if (response && response.status === 200) {
                                        this.setState({shouldCloseModal: true});
                                        if (response.data && response.data.message) {
                                            this.setState({
                                                isAlertBoxOpen: true,
                                                isSuccess: true,
                                                alert_information: response.data.message
                                            });
                                        } else {
                                            this.setState({
                                                isAlertBoxOpen: true,
                                                isSuccess: true,
                                                alert_information: response.message
                                            });
                                        }
                                    } else {
                                        this.setState({
                                            shouldCloseModal: false,
                                            isAlertBoxOpen: true,
                                            isSuccess: false,
                                            alert_information: response.data.message,
                                        });
                                    }
                                });
                            }else {
                                  let  formData = {
                                        ...industryStageData,
                                        industry: this.props.industryName,
                                        industryStageId: this.props.stageData.id,
                                        updated_by: this.props.userName
                                    };
                                   this.props.actions.editIndustryStage(formData).then((response) => {
                                   this.setState({preLoader: false});
                                    if (response && response.status === 200) {
                                      this.setState({shouldCloseModal: true});
                                         if (response.data && response.data.message) {
                                             this.setState({
                                                isAlertBoxOpen: true,
                                                isSuccess: true,
                                                alert_information: response.data.message
                                                });
                                            } else {
                                                this.setState({
                                                isAlertBoxOpen: true,
                                                isSuccess: true,
                                                alert_information: response.message
                                                });
                                            }
                                            } else {
                                                    this.setState({
                                                    shouldCloseModal: false,
                                                    isAlertBoxOpen: true,
                                                    isSuccess: false,
                                                    alert_information: response.data.message,
                                                    applicationMode: APPLICATION_MODES.EDIT
                                                    });
                                                }
                                            });
                        }
                    }
                    
                });
    }

    componentDidMount() {
      
        if (this.state.applicationMode !== APPLICATION_MODES.CREATE) {
            if (this.props.stageData) {
                this.setState({
                    ...this.props.stageData,
                    stages: this.props.stageData.stages,
                    status: this.props.stageData.status === APPLICATION_MODES.ACTIVE ? true : false
                });

            }
        }


    }

    _validations = () => {
        let mandatoryFieldsValid;
        let sequenceNoFieldValid = true;

        if (!this.state.stages ) {
            mandatoryFieldsValid = false;
        } else if (this.state.sequence_no) {
            sequenceNoFieldValid = validateSequenceNumber(this.state.sequence_no.toString());
        } else {
            mandatoryFieldsValid = true;
        }
        this.setState({
            isValid: mandatoryFieldsValid,
            validSequenceNumber: sequenceNoFieldValid

        });
        return Promise.resolve(true);
    }

    _handleInput = e => {
        if (e.target.id === "status") {

            this.setState({
                [e.target.id]: e.target.checked,
                editFlag: {
                    ...this.state.editFlag,
                    status: true
                }
            });
        } else {
            if (e.target.id === "sequence_no") {
                if (e.target.value !== "" && !Number(e.target.value)) {
                    return;
                }
            }

            this.setState({
                [e.target.name]: e.target.value,
                editFlag: {
                    ...this.state.editFlag,
                    [e.target.name]: true
                }
            });
        }
    };

    _triggerDiscard = () => {
        if (this.state.editFlag && Object.values(this.state.editFlag).includes(true)) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST,
                shouldCloseModal: true
            });
        } else {
            this.props.closeModal();
        }
    };

    _discard = data => {
        if (data === "ok") {
            if (this.state.shouldCloseModal === true) {
                this.setState({
                    //reset the state
                });
                this.props.closeModal();
            }
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _handleAppMode = () => {
        this.setState({
            applicationMode: APPLICATION_MODES.EDIT
        });
    };

    render() {
        return (
            <Fragment>
                <Row className={this.state.preLoader ? "card-container" : "card-container" + " loader-wait"}>
                    <TatvamCol s={12} className={this.state.preLoader ? "valign-wrapper form-loader-overlay" : "hide"}>
                        {this.state.preLoader && <Loader type="ball-beat"/>}
                    </TatvamCol>
                </Row>
                <Row> {this.state.applicationMode === APPLICATION_MODES.VIEW ? (
                    <h4>{STAGES_GRID.VIEW_TITLE_INDUSTRY_STAGE}</h4>) : ""}
                    {this.state.applicationMode === APPLICATION_MODES.EDIT ? (
                        <h4>{STAGES_GRID.EDIT_TITLE_INDUSTRY_STAGE}</h4>) : ""}</Row>
                {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                    <Row className="mb-2">
                       
                        <TatvamCol m={12} className="align-flex-end">


                            <TatvamCol s={12} m={4} xl={4} className="seqInput">
                                <label htmlFor="sequence_no">{STAGES_GRID.SEQ_NO}</label>
                                <TatvamTextBox
                                        m={12}
                                        id="sequence_no"
                                        name="sequence_no"
                                        onChange={this._handleInput}
                                        className="labelText mb-0 validate txt-no-h"
                                        value={this.state.sequence_no.toString()}
                                        autocomplete="off"
                                        maxlength={3}
                                        title={TEXT_VALIDATION.SEQ_NO}
                                 />
                            </TatvamCol>

                            <TatvamCol m={12} xl={6} className="no-pad-left">
                                {<label htmlFor="stages">{STAGES_GRID.STAGE_NAME} <span
                                    style={{color: 'red'}}> *</span></label>}
                                <TatvamTextBox
                                    className={(this.state.inValidForm && !this.state.stages) ? "firstItem labelText mb-0 validate txt-no-h invalid" : "firstItem labelText mb-0 validate txt-no-h "}
                                    s={12}
                                    required
                                    name="stages"
                                    onChange={this._handleInput}
                                    value={this.state.stages}
                                    autocomplete="off"
                                    maxlength={25}
                                />
                            </TatvamCol>
                           
                            <TatvamCol m={4} xl={4} className="switch-wrapper">
                                <Switch
                                    id="status"
                                    offLabel="Status"
                                    checked={this.state.status}
                                    onClick={this._handleInput}
                                    s={12}
                                />
                            </TatvamCol>
                            
                        </TatvamCol>
                       
                    </Row>
                ) : (
                    <Row>
                        <TatvamCol m={12} className="mt-.75 dimensionView ">
                            <div className="col s6 p-0">
                                <span
                                    className="property-label col s4"> {STAGES_GRID.SEQ_NO} : </span>
                                <span className="col s8 url-wrap"> {this.state.sequence_no.toString()}</span>
                            </div>

                            <div className="col s6 p-0">
                                <span
                                    className="property-label col s4"> {STAGES_GRID.STAGE_NAME} : </span>
                                <span className="col s8 url-wrap"> {this.state.stages}</span>
                            </div>
                        </TatvamCol>

                        <TatvamCol m={12} className="mt-.75 dimensionView ">
                            <div className="col s6 p-0">
                                <span className="property-label col s4"> {STAGES_GRID.STATUS} :</span>
                                <span
                                    className="col s8">{this.state.status === true ? SWITCH_TEXT.ACTIVE : SWITCH_TEXT.INACTIVE}</span>
                            </div>
                        </TatvamCol>
                     
                    </Row>
                )}
                <Row className="modalButtons p-0 mt-2">
                     <TatvamCol s={12} m={8}>
                     {!this.state.validSequenceNumber && (
                            <p className="errorText">{VALIDATION_MESSAGES.SEQUENCE_NO_INVALID}</p>
                             )}
                         </TatvamCol>

                    <TatvamCol s={12} m={12} className="pr-2">
                        {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                                <TatvamButton
                                    waves="light"
                                    className="mt-1  CreateProjectPublish btn_primary "
                                    onClick={this._handleSave}
                                    disabled={!(this.state.editFlag && Object.values(this.state.editFlag).includes(true))}
                                >
                                    {FORM_OPERATIONS.SAVE}
                                </TatvamButton>
                            ) :
                                <TatvamButton
                                    waves="light"
                                    className="mt-1 CreateProjectPublish btn_primary "
                                    name="EDIT"
                                    onClick={this._handleAppMode}
                                    disabled={this.props.nonEditable}
                                >
                                    {FORM_OPERATIONS.EDIT}
                                </TatvamButton>
                            }
                        <TatvamButton
                            waves="light"
                            className="mt-1 mr-4 CreateProjectPublish btn_primary"
                            onClick={this._triggerDiscard}
                        >
                            {this.state.applicationMode === APPLICATION_MODES.VIEW ||
                            !(this.state.editFlag && Object.values(this.state.editFlag).includes(true)) ?
                                FORM_OPERATIONS.CLOSE : FORM_OPERATIONS.DISCARD}

                        </TatvamButton>
                    </TatvamCol>
                </Row>
                <AlertBox
                    open={this.state.isAlertBoxOpen}
                    isSuccess={this.state.isSuccess}
                    closeAlertBox={this._discard}
                    isConfirmBox={this.state.isConfirmBox}
                    alert_information={this.state.alert_information}
                />
            </Fragment>
        );
    }
}

export default IndustryStageForm;