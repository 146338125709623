import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { createDimensionIndustry, createIndustryDimensionValue, deleteIndustryDimension, editDimensionIndustry, fetchAllIndustry, getCustomerDimensions, getIndustryDimensionValues, getIndustryStages, getIndustryTouchpoints, updateIndustryDimensionValue } from '../../../actions';
import DimensionIndustryForm from './dimensionIndustryForm';


const mapStateToProps = (state, ownProps) => (
    {
        userName: state.auth.userName,
        customers: state.customer.list,
        //customerDimensions: state.customer.list[ownProps.customerId].dimensions,
        //industryDimensionPermission: state.auth.permissions && state.auth.permissions.industryDimensionValue.create,
        sourceIndustryList: state.sources ? state.sources.industry : [],
        industryTouchpointsList: state.industryTouchpoints,
        industryStagesList: state.industryStages,

    });

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getIndustryDimensionValues,
            deleteIndustryDimension,
            createIndustryDimensionValue,
            updateIndustryDimensionValue,
            fetchAllIndustry,
            getIndustryTouchpoints,
            getCustomerDimensions,
            createDimensionIndustry,
            editDimensionIndustry,
            getIndustryStages,
        }, dispatch
    )
});
export default connect(mapStateToProps, mapDispatchToProps)(DimensionIndustryForm);
