import {apiUrl} from 'apiConstants';
import axios from 'axios';

const format = require("string-format");

format.extend(String.prototype, {});

const actions = {
    GetIndustryStagesSuccess: (payload) => ({
        type: "GET_INDUSTRY_STAGES_SUCCESS",
        payload
    }),
    GetIndustryStagesError: (payload) => ({
        type: "GET_INDUSTRY_STAGES_ERROR",
        payload
    }),
    DeleteIndustryStagesSuccess: (payload) => ({
        type: "DELETE_INDUSTRY_STAGES_SUCCESS",
        payload
    }),
    DeleteIndustryStagesError: (payload) => ({
        type: "DELETE_INDUSTRY_STAGES_ERROR",
        payload
    }),
    CreateIndustryStagesSuccess: (payload) => ({
        type: "CREATE_INDUSTRY_STAGES_SUCCESS",
        payload
    }),
    CreateIndustryStagesError: (payload) => ({
        type: "CREATE_INDUSTRY_STAGES_ERROR",
        payload
    }),
    EditIndustryStagesSuccess: (payload) => ({
        type: "EDIT_INDUSTRY_STAGES_SUCCESS",
        payload
    }),
    EditIndustryStagesError: (payload) => ({
        type: "EDIT_INDUSTRY_STAGES_ERROR",
        payload
    }),
};

export const getIndustryStages = (industryName) => (dispatch, getState) => {
    const state = getState();
    let industryStages = state.industryStages;

    const url = apiUrl.BASE_URL + apiUrl.GET_INSERT_INDUSTRY_STAGES.format(industryName);

    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                industryStages = {
                    ...industryStages,
                    stages: response.data.data
                };
                dispatch(actions.GetIndustryStagesSuccess(industryStages));
                return response.data.data;
            }

            dispatch(actions.GetIndustryStagesError(response.message));
            return response.data.data;
        })
        .catch(error => {
            dispatch(actions.GetIndustryStagesError(error.message));
            return Promise.resolve(error);
        });
};

export const importIndustryStages = (userData) => (dispatch) => {
    let formData = new FormData();
    formData.append("industryStages", userData.currentFile);
    const url = apiUrl.BASE_URL + apiUrl.IMPORT_INDUSTRY_STAGES.format(userData.industryName);

    return axios
        .post(url, formData, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
        .then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;

        })
        .catch(error => {
            return Promise.resolve(error.response);
        });
};

export const deleteIndustryStage = (industryStage) => (dispatch, getState) => {
    const store = getState();
    let payload;
    payload = industryStage

    const url = apiUrl.BASE_URL + apiUrl.UPDATE_DELETE_INDUSTRY_STAGE.format(industryStage.id);

    return axios
        .delete(url, payload)
        .then(response => {

            if (response.status === 200) {
           
                const updatedStagesList =  store.industryStages.industryStages.stages.filter(stage => stage.id !== industryStage.id);

                dispatch(actions.DeleteIndustryStagesSuccess(updatedStagesList));
                return response;
            }


            dispatch(actions.DeleteIndustryStagesError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.DeleteIndustryStagesError(error.message));
            return Promise.resolve(error.response);
        });
};

export const createIndustryStage = (formData) => (dispatch, getState) => {
    const store = getState();
    const payload = formData;
    const url = apiUrl.BASE_URL + apiUrl.CREATE_INDUSTRY_STAGE.format(formData.industry_name);

    return axios
        .post(url, payload)
        .then(response => {
            if (response.status === 200) {
                const stageList = dispatch(getIndustryStages(formData.industry_name));
            if (stageList) {
                dispatch(actions.CreateIndustryStagesSuccess(stageList));
            }
                return response;
            }

            dispatch(actions.CreateIndustryStagesError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.CreateIndustryStagesError(error.message));
            return Promise.resolve(error.response);
        });
};

export const editIndustryStage = (formData) => (dispatch, getState) => {
    const state = getState();
    const payload = formData;
    const url = apiUrl.BASE_URL + apiUrl.UPDATE_DELETE_INDUSTRY_STAGE.format(formData.industryStageId);
    
    let temp = { ...payload };
    delete temp['industryStageId']; 

    return axios
        .patch(url, temp)
        .then(response => {

            if (response.status === 200) {
                const stageList = dispatch(getIndustryStages(formData.industry));

                if (stageList) {
                    dispatch(actions.EditIndustryStagesSuccess(stageList));
                }

                return response;
            }

            dispatch(actions.EditIndustryStagesError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.EditIndustryStagesError(error.message));
            return Promise.resolve(error.response);
        });
};