import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import IndustryTouchpointsForm from "./industryTouchpointForm";
import { getIndustryStages,  createIndustryTouchpoint, editIndustryTouchpoint } from "../../../actions";

const mapStateToProps = (state, ownProps) => (
    {
        industryStagesList: state.industryStages,
        userName: state.auth.userName,
    });

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getIndustryStages,
            createIndustryTouchpoint,
            editIndustryTouchpoint
        }, dispatch
    )
});
export default connect(mapStateToProps, mapDispatchToProps)(IndustryTouchpointsForm);
