import React, { Fragment } from "react";
import { ALERT_MESSAGES, APP_BOOLEANS, APPLICATION_MODES, FORM_OPERATIONS, PROPERTY_RESPONSE_TEMPLATES, STAGES_GRID, SWITCH_TEXT, TEXT_VALIDATION, TOUCHPOINTS_GRID, VALIDATION_MESSAGES } from "../../../constants/appConstants";
import { TatvamButton, TatvamCol, TatvamMultiSelect, TatvamTextBox } from "../../functionalComponents";
import AlertBox from "../alertBox";
import { Row, Switch } from "react-materialize";
import Loader from "react-loaders";
import { dynamicSort } from "../../../utils/objectUtil";

class IndustryTouchpointsForm extends React.Component {
    constructor(props) {
        super(props);
        console.log('props', this.props)
        this.state = {
            shouldCloseModal: false,
            applicationMode: props.applicationMode,
            preLoader: false,
            status: false,
            isValid: true,
            editFlag: false,
            stagesListsOptions: [],
            selectedStages: [],
            sequence_no: "",
            validSequenceNumber: true,
        }
    }

    _handleSave = e => {
        e.preventDefault();
       
        this._validations().then((response) => {
            if (!this.state.isValid) {
                this.setState({inValidForm: true});
                } 
                if (response) {
                    let industryTouchpointData = {};
                    industryTouchpointData.sequence_no =$.trim(this.state.sequence_no) !== "" ? parseInt(this.state.sequence_no) : 0,
                    industryTouchpointData.touchpoints = this.state.touchpoints;
                    industryTouchpointData.status = this.state.status ? SWITCH_TEXT.ACTIVE : SWITCH_TEXT.INACTIVE;
                    industryTouchpointData.stages= this.state.selectedStages.label ? this.state.selectedStages.label : ""
                    
                                
                                    
                if (this.state.applicationMode === APPLICATION_MODES.CREATE) {
                    industryTouchpointData = {
                        industry: this.props.industryName,
                        ...industryTouchpointData,
                        created_by: this.props.userName
                    };
                    
                    this.setState({preLoader: true});
                                        
                        this.props.actions.createIndustryTouchpoint(industryTouchpointData).then(response => {
                        this.setState({preLoader: false});
                        if (response && response.status === 200) {
                            this.setState({shouldCloseModal: true});
                            if (response.data && response.data.message) {
                                this.setState({
                                isAlertBoxOpen: true,
                                isSuccess: true,
                                alert_information: response.data.message
                                });
                                } else {
                                    this.setState({
                                        isAlertBoxOpen: true,
                                        isSuccess: true,
                                        alert_information: response.message
                                        });
                                        }
                                    } else {
                                        this.setState({
                                        shouldCloseModal: false,
                                        isAlertBoxOpen: true,
                                        isSuccess: false,
                                        alert_information: response.data.message,
                                        });
                                    }
                    });
                }else {
                        let  formData = {
                                ...industryTouchpointData,
                                industry: this.props.industryName,
                                industryTouchpointId: this.props.touchpointData.id,
                                updated_by: this.props.userName
                        };
                            this.props.actions.editIndustryTouchpoint(formData).then((response) => {
                            this.setState({preLoader: false});
                            if (response && response.status === 200) {
                            this.setState({shouldCloseModal: true});
                            if (response.data && response.data.message) {
                                this.setState({
                                    isAlertBoxOpen: true,
                                    isSuccess: true,
                                    alert_information: response.data.message
                                });
                                } else {
                                    this.setState({
                                        isAlertBoxOpen: true,
                                        isSuccess: true,
                                        alert_information: response.message
                                        });
                                    }
                                    } else {
                                        this.setState({
                                            shouldCloseModal: false,
                                            isAlertBoxOpen: true,
                                            isSuccess: false,
                                            alert_information: response.data.message,
                                            applicationMode: APPLICATION_MODES.EDIT
                                        });
                                    }
                                });
                }
            }
                            
        });
    
    }

    loadStagesDropdown = (stagesListData) => {
            const stageLst = [];
            stageLst.push({label: "", value: ""});
            stagesListData.map((data) => {
                if (data.status === APPLICATION_MODES.ACTIVE) {
                    const stageName = data.stages;
                    stageLst.push({label: stageName, value: data.id});
                }
            });
            stageLst.sort(dynamicSort("label"));
            stageLst[0].label = PROPERTY_RESPONSE_TEMPLATES.SELECT_SOURCE;
            this.setState({
                stagesListsOptions: stageLst
            });
    };

    componentDidMount() {
        if (this.props.industryStagesList.industryStages.stages) {
            this.loadStagesDropdown(this.props.industryStagesList.industryStages.stages);
        }

        if (this.state.applicationMode !== APPLICATION_MODES.CREATE) {
            if (this.props.touchpointData) {

                let selectedStages = {label: TOUCHPOINTS_GRID.SELECT_STAGES, value: ""};
               
                selectedStages = {
                    label: this.props.touchpointData.stages,
                    value: this.props.touchpointData.stages
                };

                this.setState({
                    ...this.props.touchpointData,
                    touchpoints: this.props.touchpointData.touchpoints,
                    selectedStages: selectedStages,
                    status: this.props.touchpointData.status === APPLICATION_MODES.ACTIVE ? true : false
                });

            }
        }


    }

    componentWillReceiveProps(props) {
        if (props.industryStagesList.industryStages.stages) {
            this.loadStagesDropdown(props.industryStagesList.industryStages.stages);
        }

        if (this.state.applicationMode !== APPLICATION_MODES.CREATE) {
            if (props.touchpointData) {

                let selectedStages = {label: TOUCHPOINTS_GRID.SELECT_STAGES, value: ""};
               
                selectedStages = {
                    label: props.touchpointData.stages,
                    value: props.touchpointData.stages
                };

                setState({
                    ...props.touchpointData,
                    touchpoints: props.touchpointData.touchpoints,
                    selectedStages: selectedStages,
                    status: props.touchpointData.status === APPLICATION_MODES.ACTIVE ? true : false
                });

            }
        }
    }

    _validations = () => {
        let mandatoryFieldsValid;
        let sequenceNoFieldValid = true;
    
        if (!this.state.stages ) {
             mandatoryFieldsValid = false;
        } else if (this.state.sequence_no) {
            sequenceNoFieldValid = validateSequenceNumber(this.state.sequence_no.toString());
        } else {
            mandatoryFieldsValid = true;
        }
        this.setState({
            isValid: mandatoryFieldsValid,
            validSequenceNumber: sequenceNoFieldValid
    
        });
        return Promise.resolve(true);
    }

    _handleInput = e => {
        if (e.target.id === "status") {

            this.setState({
                [e.target.id]: e.target.checked,
                editFlag: {
                    ...this.state.editFlag,
                    status: true
                }
            });
        }else {
            if (e.target.id === "sequence_no") {
                if (e.target.value !== "" && !Number(e.target.value)) {
                    return;
                }
            }

            this.setState({
                [e.target.name]: e.target.value,
                editFlag: {
                    ...this.state.editFlag,
                    [e.target.name]: true
                }
            });
        }
    };

    _handleStagesSelect = selectedOption => {
        this.setState({
            editFlag: {
                ...this.state.editFlag,
            },
            selectedStages: selectedOption
        });
    };

    _triggerDiscard = () => {
        if (this.state.editFlag && Object.values(this.state.editFlag).includes(true)) {
            this.setState({
                isConfirmBox: true,
                isAlertBoxOpen: true,
                isSuccess: false,
                alert_information: ALERT_MESSAGES.CHANGES_WILL_BE_LOST,
                shouldCloseModal: true
            });
        } else {
            this.props.closeModal();
        }
    };

    _discard = data => {
        if (data === "ok") {
            if (this.state.shouldCloseModal === true) {
                this.setState({
                    //reset the state
                });
                this.props.closeModal();
            }
        }
        this.setState({
            isConfirmBox: false,
            isAlertBoxOpen: false,
            isSuccess: false
        });
    };

    _handleAppMode = () => {
        this.setState({
            applicationMode: APPLICATION_MODES.EDIT
        });
    };

    render() {
        return (
            <Fragment>
                <Row className={this.state.preLoader ? "card-container" : "card-container" + " loader-wait"}>
                    <TatvamCol s={12} className={this.state.preLoader ? "valign-wrapper form-loader-overlay" : "hide"}>
                        {this.state.preLoader && <Loader type="ball-beat"/>}
                    </TatvamCol>
                </Row>
                <Row> {this.state.applicationMode === APPLICATION_MODES.VIEW ? (
                    <h4>{TOUCHPOINTS_GRID.VIEW_TITLE_INDUSTRY_TOUCHPOINT}</h4>) : ""}
                    {this.state.applicationMode === APPLICATION_MODES.EDIT ? (
                        <h4>{TOUCHPOINTS_GRID.EDIT_TITLE_INDUSTRY_TOUCHPOINT}</h4>) : ""}</Row>
                {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                    <Row className="mb-2">
                        <TatvamCol m={4} className="offset-m9">
                        </TatvamCol>
                        <TatvamCol m={12} className="align-flex-end">

                        <TatvamCol m={12} xl={6}>
                                <label htmlFor="stages">{TOUCHPOINTS_GRID.STAGES}</label>
                                <TatvamMultiSelect
                                    classNamePrefix="react-select"
                                    className="validate select-ht "
                                    value={this.state.selectedStages}
                                    name="stages"
                                    id="stages"
                                    onChange={this._handleStagesSelect}
                                    options={this.state.stagesListsOptions}
                                    placeholder={TOUCHPOINTS_GRID.SELECT_STAGES}
                                />
                        </TatvamCol>

                            <TatvamCol m={12} xl={6} className="no-pad-left">
                                {<label htmlFor="touchpoints">{TOUCHPOINTS_GRID.TOUCHPOINT_NAME} <span
                                    style={{color: 'red'}}> *</span></label>}
                                <TatvamTextBox
                                    className={(this.state.inValidForm && !this.state.touchpoints) ? "firstItem labelText mb-0 validate txt-no-h invalid" : "firstItem labelText mb-0 validate txt-no-h "}
                                    s={12}
                                    required
                                    name="touchpoints"
                                    onChange={this._handleInput}
                                    value={this.state.touchpoints}
                                    autocomplete="off"
                                    maxlength={25}
                                />
                            </TatvamCol>
                           
                        </TatvamCol>
                       
                        <TatvamCol m={12}>
                             <TatvamCol s={12} m={4} xl={4} className="seqInput">
                                    <label htmlFor="sequence_no">{STAGES_GRID.SEQ_NO}</label>
                                        <TatvamTextBox
                                            m={12}
                                            id="sequence_no"
                                            name="sequence_no"
                                            onChange={this._handleInput}
                                            className="labelText mb-0 validate txt-no-h"
                                            value={this.state.sequence_no.toString()}
                                            autocomplete="off"
                                            maxlength={3}
                                            title={TEXT_VALIDATION.SEQ_NO}
                                            />
                            </TatvamCol>

                            <TatvamCol s={6} className="switch-wrapper">
                                <Switch
                                    id="status"
                                    offLabel="Status"
                                    checked={this.state.status}
                                    onClick={this._handleInput}
                                    s={12}
                                />
                            </TatvamCol>
                        </TatvamCol>

                    </Row>
                ) : (
                    <Row>
                        <TatvamCol m={12} className="mt-.75 dimensionView ">
                            <div className="col s6 p-0">
                                <span
                                    className="property-label col s4"> {TOUCHPOINTS_GRID.TOUCHPOINT_NAME} : </span>
                                <span className="col s8 url-wrap"> {this.state.touchpoints}</span>
                            </div>

                            <div className="col s6 p-0">
                                <span
                                    className="property-label col s4"> {TOUCHPOINTS_GRID.STAGE_NAME} : </span>
                                <span className="col s8 url-wrap"> {this.state.selectedStages.label}</span>
                            </div>
                        </TatvamCol>

                        <TatvamCol m={12} className="mt-.75 dimensionView ">
                            <div className="col s6 p-0">
                                <span
                                    className="property-label col s4"> {STAGES_GRID.SEQ_NO} : </span>
                                <span className="col s8 url-wrap"> {this.state.sequence_no.toString()}</span>
                            </div>

                        <div className="col s6 p-0">
                                <span className="property-label col s4"> {TOUCHPOINTS_GRID.STATUS} :</span>
                                <span
                                    className="col s8">{this.state.status === true ? SWITCH_TEXT.ACTIVE : SWITCH_TEXT.INACTIVE}</span>
                            </div>
                        </TatvamCol>
                     
                    </Row>
                )}
                <Row className="modalButtons p-0 mt-2">
                    
                    <TatvamCol s={12} m={8}>
                        {!this.state.validSequenceNumber && (
                            <p className="errorText">{VALIDATION_MESSAGES.SEQUENCE_NO_INVALID}</p>
                        )}
                    </TatvamCol>

                    <TatvamCol s={12} m={12} className="pr-2">
                        {this.state.applicationMode !== APPLICATION_MODES.VIEW ? (
                                <TatvamButton
                                    waves="light"
                                    className="mt-1  CreateProjectPublish btn_primary "
                                    onClick={this._handleSave}
                                    disabled={!(this.state.editFlag && Object.values(this.state.editFlag).includes(true))}
                                >
                                    {FORM_OPERATIONS.SAVE}
                                </TatvamButton>
                            ) :
                                <TatvamButton
                                    waves="light"
                                    className="mt-1 CreateProjectPublish btn_primary "
                                    name="EDIT"
                                    onClick={this._handleAppMode}
                                    disabled={this.props.nonEditable}
                                >
                                    {FORM_OPERATIONS.EDIT}
                                </TatvamButton>
                            }
                        <TatvamButton
                            waves="light"
                            className="mt-1 mr-4 CreateProjectPublish btn_primary"
                            onClick={this._triggerDiscard}
                        >
                            {this.state.applicationMode === APPLICATION_MODES.VIEW ||
                            !(this.state.editFlag && Object.values(this.state.editFlag).includes(true)) ?
                                FORM_OPERATIONS.CLOSE : FORM_OPERATIONS.DISCARD}

                        </TatvamButton>
                    </TatvamCol>
                </Row>
                <AlertBox
                    open={this.state.isAlertBoxOpen}
                    isSuccess={this.state.isSuccess}
                    closeAlertBox={this._discard}
                    isConfirmBox={this.state.isConfirmBox}
                    alert_information={this.state.alert_information}
                />
            </Fragment>
        );
    }
}

export default IndustryTouchpointsForm;