import {apiUrl} from 'apiConstants';
import axios from 'axios';

const format = require("string-format");

format.extend(String.prototype, {});

const actions = {
    GetIndustryDimensionsSuccess: (payload) => ({
        type: "GET_INDUSTRY_DIMENSION_SUCCESS",
        payload
    }),
    GetIndustryDimensionsError: (payload) => ({
        type: "GET_INDUSTRY_DIMENSION_ERROR",
        payload
    }),
    DeleteIndustryDimensionsSuccess: (payload) => ({
        type: "DELETE_DIMENSION_INDUSTRY_SUCCESS",
        payload
    }),
    DeleteIndustryDimensionsError: (payload) => ({
        type: "DELETE_DIMENSION_INDUSTRY_ERROR",
        payload
    }),
    CreateDimensionIndustrySuccess: (payload) => ({
        type: "CREATE_DIMENSION_INDUSTRY_SUCCESS",
        payload
    }),
    CreateDimensionIndustryError: (payload) => ({
        type: "CREATE_DIMENSION_INDUSTRY_ERROR",
        payload
    }),
    EditDimensionIndustrySuccess: (payload) => ({
        type: "EDIT_DIMENSION_INDUSTRY_SUCCESS",
        payload
    }),
    EditDimensionIndustryError: (payload) => ({
        type: "EDIT_DIMENSION_INDUSTRY_ERROR",
        payload
    }),
};

export const getIndustryDimensionsValuesByIndustryName = (industryName) => (dispatch, getState) => {
    const state = getState();
    let industryDimensionsValues = state.dimensionIndustry.dimensionIndustry;

    const url = apiUrl.BASE_URL + apiUrl.GET_INDUSTRY_DIMENSION.format(industryName);

    return axios
        .get(url)
        .then(response => {
            if (response.status === 200) {
                industryDimensionsValues = {
                    ...industryDimensionsValues,
                    dimensionsGridValues: response.data.data
                };
                dispatch(actions.GetIndustryDimensionsSuccess(industryDimensionsValues));
                return response;
            }

            dispatch(actions.GetIndustryDimensionsError(response.message));
            return response;
        })
        .catch(error => {
            dispatch(actions.GetIndustryDimensionsError(error.message));
            return Promise.resolve(error);
        });
};


export const importIndustryDimensionsValues = (userData) => (dispatch) => {
    let formData = new FormData();
    formData.append("industryStages", userData.currentFile);
    const url = apiUrl.BASE_URL + apiUrl.IMPORT_INDUSTRY_DIMENSION.format(userData.industryName);

    return axios
        .post(url, formData, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
        .then(response => {
            if (response.status === 200) {
                return response;
            }
            return response;

        })
        .catch(error => {
            return Promise.resolve(error.response);
        });
};


export const deleteDimensionIndustry = (industryDimension) => (dispatch, getState) => {
    const store = getState();
    let payload;
    payload = industryDimension

    const url = apiUrl.BASE_URL + apiUrl.DELETE_INDUSTRY_DIMENSION.format(industryDimension.id);
    return axios
        .delete(url, payload)
        .then(response => {

            if (response.status === 200) {
           
                const updateddimensionIndustryList =  store.dimensionIndustry.dimensionIndustry.dimensionsGridValues.filter(ele => ele.id !== industryDimension.id);

                dispatch(actions.DeleteIndustryDimensionsSuccess(updateddimensionIndustryList));
                return response;
            }


            dispatch(actions.DeleteIndustryTouchpointsError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.DeleteIndustryDimensionsError(error.message));
            return Promise.resolve(error.response);
        });
};

export const createDimensionIndustry = (formData) => (dispatch, getState) => {
    const store = getState();
    const payload = formData;
    const url = apiUrl.BASE_URL + apiUrl.CREATE_DIMENSION_INDUSTRY.format(formData.industry);

    return axios
        .post(url, payload)
        .then(response => {
            if (response.status === 200) {
                const dimensionsList = dispatch(getIndustryDimensionsValuesByIndustryName(formData.industry));
            if (dimensionsList) {
                dispatch(actions.CreateDimensionIndustrySuccess(dimensionsList));
            }
                return response;
            }

            dispatch(actions.CreateDimensionIndustryError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.CreateDimensionIndustryError(error.message));
            return Promise.resolve(error.response);
        });
};

export const editDimensionIndustry = (formData) => (dispatch, getState) => {
    const state = getState();
    const payload = formData;
    const url = apiUrl.BASE_URL + apiUrl.EDIT_DIMENSION_INDUSTRY.format(formData.industryDimensionId);
    
    let temp = { ...payload };
    delete temp['industryDimensionId']; 
    return axios
        .patch(url, temp)
        .then(response => {

            if (response.status === 200) {
                const dimensionsList = dispatch(getIndustryDimensionsValuesByIndustryName(formData.industry));

                if (dimensionsList) {
                    dispatch(actions.EditDimensionIndustrySuccess(dimensionsList));
                }

                return response;
            }

            dispatch(actions.EditDimensionIndustryError(response.message));
            return response;

        })
        .catch(error => {
            dispatch(actions.EditDimensionIndustryError(error.message));
            return Promise.resolve(error.response);
        });
};